<template>
  <div>
    <v-dialog
      v-model="open"
      transition="scale-transition"
      persistent
      max-width="500px"
    >
      <v-card flat>
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <span class="font-weight-bold">
                {{ $t("e_test.student_grade_details") }}
              </span>
            </v-col>
            <v-col cols="6">
              <div style="position: absolute; right: 25px;">
                <v-icon :disabled="loadingButton" @click="closeDialog"
                  >mdi-close</v-icon
                >
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider class="my-1" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="valueSelected" hide-details>
            <v-row
              v-for="(item, index) in data.student_score"
              :key="index"
              class="my-3"
            >
              <v-col cols="12" sm="7">
                {{ item.title }}
              </v-col>
              <v-col cols="12" sm="3" class="d-flex align-end flex-column">
                <span class="font-weight-bold">
                  {{ item.score }}
                </span>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex align-end flex-column">
                <v-radio :value="item" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-btn
              :disabled="loadingButton"
              outlined
              class="mr-2"
              color="primary"
              @click="closeDialog"
            >
              {{ $t("app.cancel") }}</v-btn
            >
            <v-btn
              :loading="loadingButton"
              class="gradient-primary"
              depressed
              dark
              @click="save"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { updateValueStudent } from "@/api/admin/academic/eTest";

export default {
  props: {
    open: Boolean,
    data: Object
  },
  watch: {
    open(data) {
      if (data)
        this.valueSelected = this.data.student_score.find(
          item => item.selected
        );
    }
  },
  data() {
    return {
      valueSelected: {},
      loadingButton: false,
      tableHeaders: [
        {
          value: "title"
        },
        {
          value: "score"
        },
        { value: "selected" }
      ]
    };
  },
  methods: {
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async save() {
      this.loadingButton = true;
      try {
        const response = await updateValueStudent({
          ...this.valueSelected,
          student: this.data.student
        });
        if (response.data.code) {
          this.snackBar(true, response.data.message);
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingButton = false;
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>
